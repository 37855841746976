/*
 * SPDX-FileCopyrightText: 2023 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

@import "variables.scss";
@import '~@tol/tol-ui/src/scss/tol-styling.scss';

.tv-grc {
    .tv-logo {
        height: 100px;
        // width: 450px;
        flex-shrink: 0;
    }
    .grc-logo {
        padding: 10px;
    }
    .wtsi-logo {
        padding: 10px;
    }
    .sanger-logo {
        padding: 10px;
        margin-bottom: 10px;
    }
    .mgi-logo {
        padding: 10px;
    }
    .emblebi-logo {
        padding: 10px;
    }
    .ncbi-logo {
        padding: 10px;
    }
    .rgd-logo {
        padding: 10px;
    }
    .zfin-logo {
        padding: 10px;
    }

    .human-silhouette {
        height: 120px;
        padding: 10px;
    }
    .mouse-silhouette {
        width: 150px;
        padding: 10px;
    }
    .td-silhouette {
        width: 150px;
        padding: 10px;
    }
    .rat-silhouette {
        width: 150px;
        padding: 10px;
    }
    .zebrafish-silhouette {
        width: 150px;
        padding: 10px;
    }
    .species-card {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
        text-align: center;
    }
    .link-card {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
        text-align: center;
    }
    .image-section {
        display: flex;
        align-items: flex-start;
        flex-direction: wrap;
        justify-content: center;
    }
}

.human {
    .silhouette {
        height: 200px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
    }
    .browser-button {
        margin: 10px;
    }
}
.mouse {
    .silhouette {
        height: 120px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
    }
    .browser-button {
        margin: 10px;
    }
}
.rat {
    .silhouette {
        height: 120px;
        padding: 10px;
        margin-right: 10px;
    }
    .image-section {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
    }
    .browser-button {
        margin: 10px;
    }
}
.zebrafish {
    .silhouette {
        height: 120px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
    }
    .browser-button {
        margin: 10px;
    }
}
.tasmaniandevil {
    .silhouette {
        height: 120px;
        padding: 10px;
    }
    .image-section {
        display: flex;
        align-items: flex-end;
        flex-direction: wrap;
        justify-content: center;
    }
    .browser-button {
        margin: 10px;
    }
}
